<template>
  <div> 
    <div class="panel-body row">
      <div class="col-sm-9" style="padding-left:0px;">
        <div class="input-group col-sm-3" style="padding-left:15px;">
          <input type="text" v-model="searchKey" class="form-control" id="input-store" placeholder="Search Store" value="" name="voucher">          
        </div>
      </div>
      <div class="col-sm-3" style="padding-right:0px;">
      </div>
    </div>
    <div id="table-content">  
      <table id="example" class="table table-striped table-border-out" >
        <thead>
          <tr>
            <th scope="col" class="head-table">Campaing Title</th>
            <th scope="col" class="head-table">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="column in tableItems" :key="column._id">
            <td ><b>{{ column.status }}</b></td>
            <td ><b>{{ column.date}}</b></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row text-right">
      <div class="col-sm-12">
         <div class="mt-3">
        <b-pagination 
        v-model="currentPage" 
        :per-page="pageSize"
        :total-rows="rowcount" 
        align="right"></b-pagination>
      </div>  
      </div>
    </div>
  </div>
 
</template>
<script>
export default {
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Notification Center",
          href: "/merchant-notification-center",
          active: true
        }
      ],
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
    //   tableItems: [],
      tableItems: [
        {
           _id:'123',
           date: "2019-12-05",
         status: "Sample Campaing Title 01",
         amount: "RS.5,000",
        },
        {
           _id:'234',
           date: "2019-12-06",
         status: "Sample Campaing Title 02",
         amount: "RS.6,000",
        },
        {
           _id:'345',
           date: "2019-12-07",
         status: "Sample Campaing Title 03",
         amount: "RS.7,000",
        },
        {
           _id:'567',
           date: "2019-12-07",
         status: "Sample Campaing Title 04",
         amount: "RS.8,000",
        }
      ],
    }
  },
  watch: {},
  created(){
    this.handleBreadcrumb();
    this.setMerchQuestionList();
  },
  computed: {
   
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },handleView: function() {
      this.$router.push({ name: "Order Configure" });
    },
    setMerchQuestionList: async function(){
      try {
        this.tableItems = response.map(obj=> {
          return obj;
      });

		  } catch (error) {
        throw error;
      }
    }
  }
}
</script>
<style >
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.action-style {
  width: 205px;
}
.report-admin-btn{
  margin-right: 5px;
}
.actions-btn .report-admin-btn:hover{
  color: #ffffff;
}
.actions-btn .report-admin-btn:focus{
  color: #ffffff;
}
</style>