import Domain from "../domain";
export default {
  methods: {
    merchantEmailVerify: async function(){
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/profile/email/verify/resend",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getProfileBacicInfo: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/profile/details",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getMerchantAlert: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/notification/pending?&notification_type=4",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getBankListInfo: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/profile/bank/list",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getBranchListInfo: async function(bId) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/profile/branch/list/" + bId,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putProfileBacicInfo: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/profile", obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error.response;
      }
    },
    putProfileImage: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/profile/image", obj,
          Domain.APP_MERCHANT_HEADER_FORM_DATA
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putMerchantBannerImage: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/store/banner", obj,
          Domain.APP_MERCHANT_HEADER_FORM_DATA
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putMerchantStoeProfileImage: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/store/profile/image", obj,
          Domain.APP_MERCHANT_HEADER_FORM_DATA
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putProfileAddress: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/profile/addresses", obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    deleteProfileAddress: async function(id) {
      try {
        let response = await this.axios.delete(
          Domain.APP_DOMAIN + "merchant/profile/addresses/"+id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putKyc: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/profile/kyc", obj,
          Domain.APP_MERCHANT_HEADER_FORM_DATA
        );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    putAddressKyc: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/profile/kyc/address/update", obj,
          Domain.APP_MERCHANT_HEADER_FORM_DATA
        );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    // putKycUpdate: async function(obj) {
    //   try {
    //     let response = await this.axios.put(
    //       Domain.APP_DOMAIN + "merchant/profile/kyc/update", obj,
    //       Domain.APP_MERCHANT_HEADER_FORM_DATA
    //     );
    //     return response.data;
    //   } catch (error) {
    //     throw error;
    //   }
    // },
    merchantMobileVerifyProfile: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/profile/new/mobile/otp/verify", obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    merchantChangeMobileProfile: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/profile/new/mobile/otp", obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    putCertificateImgCreate: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/profile/business/certificate/image", obj,
          Domain.APP_MERCHANT_HEADER_FORM_DATA
        );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    putCertificateImgUpdate: async function(obj) {
      try {
        let response = await this.axios.put(
          // Domain.APP_DOMAIN + "merchant/profile/kyc/certificate/update", obj,
          Domain.APP_DOMAIN + "merchant/profile/business/certificate/image", obj,
          Domain.APP_MERCHANT_HEADER_FORM_DATA
        );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    putDeliveryPatner: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/profile/update/delivy", obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    getAnnouncementAll: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/announcement/details",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getAnnouncementInfo: async function(page, perpage) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/announcement/details/?page=" + page + "&perpage=" + perpage,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getDashboardStage: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/stage/details",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    postDashboardStage: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/dashboard/stage", obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putAlertClick: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/notification/watched", obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    postMobileChange: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/profile/new/mobile/otp", obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    getMerchantChatUsers: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/product/user/chat/details",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getMerchantChatByUserID: async function(uid) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/product/user/chat/list/" + uid,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putProfileFormBacicInfo: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/profile/kyc/bank/update", obj,
          Domain.APP_MERCHANT_HEADER_FORM_DATA
        );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    putProfileChangePassword: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/profile/password/change", obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    }
  }
};
