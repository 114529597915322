import Domain from "../domain";
export default {
  methods: {
    getOrderList: async function(params) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/new/details?" + params,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOrderListCount: async function(params) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/details/count?" + params,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    printOrderDetails: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN +
            "merchant/order/group/details/invoice/" +
            id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOrderLinesByOrderId: async function(orderId) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/new/details/" + orderId,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOrdersByStoreApi: async function(params) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/new/details?store=" + params, // theekshana dev
          // Domain.APP_DOMAIN + "merchant/order/details?store="+ params,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOrderListByStore: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/not/approved/list?store="+ id,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOrderListNotApprById: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/not/approved/list/"+ id,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    changeStatusOrder: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/order/bank/status", obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getStores: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/store/details",
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getStoreById: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/store/details/" + id,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    postStore: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/store", obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error.response;
      }
    },
    putStore: async function(obj) {
      try {
        console.log("sdsdsdsd2123");
        console.log(obj)
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/store", obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error.response;
      }
    },
    allWach: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/notification/all/watched", obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error.response;
      }
    },
    singalWach: async function(obj) {
      try {
        console.log("obj");
        console.log(obj);
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/notification/watched", obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error.response;
      }
    },
    deleteStores: async function(id) {
      try {
        let response = await this.axios.delete(
          Domain.APP_DOMAIN + "merchant/store/" + id,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    deleteStoreAddressById: async function(id) {
      try {
        let response = await this.axios.delete(
          Domain.APP_DOMAIN + "merchant/store/addresses/" + id,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
