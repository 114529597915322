<template>
  <div> 
    <div class="panel-body row">
      <div class="col-sm-9" style="padding-left:0px;">
        <div class="input-group col-sm-3" style="padding-left:15px;">
          <input type="text" v-model="searchKey" class="form-control" id="input-store" placeholder="Search By Order ID" value="" name="voucher">          
        </div>
      </div>
      <div class="col-sm-3" style="padding-right:0px;">
      </div>
    </div>
    <div style="overflow-x: scroll;">
      <table id="example" class="table table-striped table-border-out" >
        <thead>
          <tr>
            <th scope="col" class="head-table">Order Id</th>
            <th scope="col" class="head-table">Date</th>
            <th scope="col" class="head-table" style="width: 200px;">Status</th>
            <th scope="col" class="head-table" style="width: 200px;">Amount</th>
          </tr>
        </thead>
        <tbody>
           <tr v-for="column in tableItems" :key="column._id">
              <td style="text-align: center;"><a target="_blank"  @click="goToOrders(column)"><b>{{column.order_env_number}}</b></a></td>
              <td style="min-width: 120px; text-align: center;"><b>{{ column.date}}</b></td>
              <td style="text-align: center;"> <span class="badge"
                    style="border-radius: 4px; font-size: 10px; padding: 4px 6px;" 
                    :style=" column.order_line_status === 0 ? 'background: rgb(225, 83, 55);' : column.order_line_status === 1  ? 'background: rgb(254, 205, 54);':  column.order_line_status === 10 ? 'background: #17a2b8; color: white;':  column.order_line_status === 20 ? 'background: rgb(0, 91, 170); color: white;':  column.order_line_status === 30 ? 'background: rgb(77, 161, 48); color: white;':  column.order_line_status === 40 ? 'background: rgb(77, 161, 48); color: white;':  column.order_line_status === 50 ? 'background: rgb(225, 83, 55); color: white;':  column.order_line_status === 60 ? 'background: rgb(0, 91, 170); color: white;' : 'background: rgb(0, 91, 170); color: white;'"> 
                    {{ column.order_line_status === 0 ? "Cancelled" :  
                      column.order_line_status === 1 ? "Pending":
                      column.order_line_status === 10 ? "Processing": 
                      column.order_line_status === 20 ? "Ready to ship": 
                      column.order_line_status === 30 ? "Shipped": 
                      column.order_line_status === 40 ? "Delivered":
                      column.order_line_status === 41 ? "Delivery Confimed":
                      column.order_line_status === 50 ? "Delivery Failed":
                      column.order_line_status === 60 ? "Return":
                      column.order_line_status === 80 ? "Dispute":
                      column.order_line_status === 90 ? "dispute closed":
                      "Error" }} 
                  </span></td>
              <td style="text-align: center;"><b>{{ column.total_charge }}</b></td>
            </tr>
        </tbody>
      </table>
    </div> 
    <div class="col-md-12" id="preview-area" v-if="tableItems.length === 0">
        <div class="row text-center">
            <div class="sk-chase">
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
            </div>
        </div>
    </div>
     <div class="row text-right">
      <div class="col-sm-12">
        <div class="mt-3">
          <b-pagination 
          v-model="currentPage" 
          :per-page="pageSize"
          :total-rows="rowcount" 
          align="right"></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import APIOrder from "@/mixins/merchant/APIOrder.js";
export default {
  mixins: [APIOrder],
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Notification Center",
          href: "/merchant-notification-center",
          active: true
        }
      ],
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
    //   tableItems: [],
      tableItems: [],
      orderArray: [],
      searchArray:[],
      orderCount:0
    }
  },
  created(){
    this.handleBreadcrumb();
    this.setMerchQuestionList();
  },
  watch: {
    searchKey: function(val) {
      this.searchOrder(val);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
        this.setMerchQuestionList(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchOrder(this.searchKey);
    }
  },
  methods: {
    goToOrders: function(val) {
      if(val.order_line_id != undefined){
        this.$router.push({ name: "Order Configure", params: { id: val.order_line_id } });
      }
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },handleView: function() {
      this.$router.push({ name: "Order Configure" });
    },
    setMerchQuestionList: async function(val, val1) {
      try {
        let array = await this.getMerchantOrderNotification();
        let response = await this.getMerchantOrderNotificationByPagination(val1, val);
        this.setOrderNotificationCount(array.length);
        this.orderArray = response;
        this.orderArray.map(obj =>{
          if (obj.order_line_status === 1) {
            obj.order_line_status_name ="Pending";
          }
          if (obj.order_line_status === 10) {
            obj.order_line_status_name ="Processing";
          }
          if (obj.order_line_status === 20) {
            obj.order_line_status_name ="Ready to ship";
          }
          if (obj.order_line_status === 30) {
            obj.order_line_status_name ="Shipped";
          }
          if (obj.order_line_status === 40) {
            obj.order_line_status_name ="Delivered";
          }
          if (obj.order_line_status === 41) {
            obj.order_line_status_name ="Delivery Confimed";
          }
          if (obj.order_line_status === 50) {
            obj.order_line_status_name ="Delivery Failed";
          }
          if (obj.order_line_status === 60) {
            obj.order_line_status_name ="Return";
          }
          if (obj.order_line_status === 80) {
            obj.order_line_status_name ="Dispute";
          }
          if (obj.order_line_status === 90) {
            obj.order_line_status_name ="dispute closed";
          }

          if (obj.total_charge != null ) {
            obj.total_charge =  "Rs." + "" + obj.total_charge
          }
          
        })
        this.blockLoading = false;
        this.rowcount = array.length;
        this.orderCount = this.rowcount
        this.searchOrder("");
		  } catch (error) {
        throw error;
      }
    },
    searchOrder: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      this.searchArray = [];
      if (!keyword || keyword.length === 0) {
        this.tableItems = this.orderArray.slice(0, pagesize);
        this.rowcount = this.orderCount;
      } else {
          this.searchArray = this.orderArray
            .filter(obj => {
              {
                return (
                  (obj.order_env_number &&
                    obj.order_env_number.includes(keyword)) || 
                  (obj.date && obj.date.includes(keyword)) || 
                  (obj.order_line_status_name && obj.order_line_status_name.toLowerCase().includes(keyword.toLowerCase())) ||
                  (obj.total_charge && obj.total_charge.toLowerCase().includes(keyword.toLowerCase()))
                );
              }
            })
          this.tableItems = this.searchArray.slice(0, pagesize);
        this.rowcount = this.searchArray.length;
      } 
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      let endsize = pagesize * currentPage;
      let startsize = 0;
      if(currentPage === 1){
        startsize = 0;
      } else {
        startsize = endsize - (pagesize) ;
      }
      if (this.searchArray.length == 0) {
        this.tableItems = this.orderArray.slice(startsize, endsize);
      } else {
        this.tableItems = this.searchArray.slice(startsize, endsize);
      }
    },
    setOrderNotificationCount: function(count) {
      this.$emit("orderCount", count);
    }
  }
}
</script>
<style >
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.action-style {
  width: 205px;
}
.report-admin-btn{
  margin-right: 5px;
}
.actions-btn .report-admin-btn:hover{
  color: #ffffff;
}
.actions-btn .report-admin-btn:focus{
  color: #ffffff;
}
</style>