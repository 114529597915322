import Domain from "../domain";
import { async } from "q";
export default {
  methods: {
    getWarrantyPeriods: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/warranty/period",
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getDownlordSheet: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "common/categories/level/assing/download",
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    postSkuImage: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/product/upload/image",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getProducts: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/product/details",
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getProductsByStoreId: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/product/details/?store=" + id,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getStoreProductData: async function(id, srchString) {
      try {
        let response = await this.axios.get(
          // Domain.APP_DOMAIN + "merchant/product/details/?search="+srchString+"&store="+id,
          Domain.APP_DOMAIN +
            "merchant/product/details/?name=" +
            srchString +
            "&store=" +
            id,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getProductByIdWithStore: async function(id, srchString) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN +
            "merchant/product/details/" +
            id +
            "?store=" +
            srchString,
          Domain.APP_MERCHANT_HEADER
        );
        console.log(response)
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getStoreProductDataRole2: async function(srchString) {
      try {
        let response = await this.axios.get(
          // Domain.APP_DOMAIN + "merchant/product/details/?search="+srchString,
          Domain.APP_DOMAIN + "merchant/product/details/?name=" + srchString,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getStoreProductDataRoleNew: async function() {
      try {
        let response = await this.axios.get(
          // Domain.APP_DOMAIN + "merchant/product/details/?search="+srchString,
          Domain.APP_DOMAIN + "merchant/product/details",
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getProductByID: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/product/details/" + id,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    postProductCreate: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/product/create",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putProductCreate: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/product",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putProductStatus: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/product/status",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putProductStatusByAdmin: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/product/status/update/all",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getMerchQuestionList: async function(id = "") {
      if (id != "") {
        try {
          let response = await this.axios.get(
            Domain.APP_DOMAIN + "merchant/product/questions/by/merchant/" + id,
            Domain.APP_MERCHANT_HEADER
          );
          if (!response || !response.data) {
            throw Error("resource not found.");
          }
          return response.data;
        } catch (error) {
          throw error;
        }
      } else {
        try {
          let response = await this.axios.get(
            Domain.APP_DOMAIN + "merchant/product/questions/by/merchant/",
            Domain.APP_MERCHANT_HEADER
          );
          if (!response || !response.data) {
            throw Error("resource not found.");
          }
          return response.data;
        } catch (error) {
          throw error;
        }
      }
    },
    putInquiryAnswers: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/product/questions/",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    postNewMerchantBrand: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/product/brand/create",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    delInquiry: async function(id) {
      try {
        let response = await this.axios.delete(
          Domain.APP_DOMAIN + "merchant/product/questions/" + id,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getDisputePeriod: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dispute/period",
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getDeliveryPartners: async function(val) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN +
            "merchant/product/delivery/partners?pickup=" +
            val,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOwnDeliveryPartners: async function(val) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN +
            "merchant/product/delivery/partners?deliver_type=" +
            val,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    createOwnDeliveryPartner: async function(val) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN +
            "merchant/product/create/own/delivery?deliver_type=" +
            val,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getDeliveryProductPrice: async function(data) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/product/final/price",
          data,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        return error.response.data;
      }
    },
    getNotificationList: async function(store = "", page = "", perpage = "") {
      try {
        let relStore = store;
        let relPage = page;
        let relPerPage = perpage;

        let response = await this.axios.get(
          Domain.APP_DOMAIN +
            "merchant/product/review/notification?store=" +
            relStore +
            "&page=" +
            relPage +
            "&perpage=" +
            relPerPage,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    postUploadProductBulk: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/product/bulk/create?type=product",
          obj,
          Domain.APP_MERCHANT_HEADER_FORM_DATA
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    getStockUploadBulk: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/product/store/product/list/download",
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getStockUploadBulkSub: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN +
            "merchant/product/store/product/list/download?store=" +
            id,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    getStockUploadFreeshippingBulk: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN +
            "merchant/product/free/shipping/list/download?store=" +
            id,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    postUploadFreeshippingStockBulk: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/product/bulk/free/shipping?type=free_shipping",
          obj,
          Domain.APP_MERCHANT_HEADER_FORM_DATA
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    postUploadStockBulk: async function(obj, sId) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/product/bulk/stock?type=stock&store=" + sId,
          obj,
          Domain.APP_MERCHANT_HEADER_FORM_DATA
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putAllStoreProductSave: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/product/details/update/by/sku",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    putStoreProductQTYSave: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "merchant/product/individual/update",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    },
  },
};
