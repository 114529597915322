<template>
  <div>
        <div id="table-content" class="col-md-12 row review">
            <div class="col-sm-12">
                <table style="overflow-y: auto; margin-bottom: 0px; margin-left: -26px;" class="table table-striped">
                    <tbody>
                        <!-- <tr v-if="tableItems.length === 0">
                            <td colspan="7" class="text-center col-md-12">
                            No Records Found!
                            </td>
                        </tr> -->
                        <tr v-for="(review, index) in tableItems" :key="index">
                            <td class="text-left" style="width: 100px;">
                                <img class="img-thumbnail" width="80px" :src="review.productImage != undefined ? review.productImage.image != undefined ? review.productImage.image:'':''" />
                            </td>
                            <td class="text-left" style="vertical-align: middle;">
                                <div>
                                    <b>
                                        {{ review.product != undefined ? review.product.product_name!= undefined ? review.product.product_name : "": "" }}
                                    </b>
                                </div>
                            </td>
                            <td class="text-left" style="vertical-align: middle; width: 400px;">
                                <button type="button" class="btn btn-sm add-buy-btn" data-toggle="collapse" :data-target="'#demo_'+ index">Comments</button>
                                <div :id="'demo_'+ index" class="collapse" style="width: 400px; padding-top: 10px;">
                                    <span>
                                        <p>
                                            <b>{{ review.comment != undefined ? review.comment : ""}}</b><br>
                                            {{ formatDate(review.created_date != undefined ? review.created_date :"") }}
                                        </p>
                                    </span>
                                </div> 
                            </td> 
                            <td class="text-left" style="vertical-align: middle;">
                                <div class="col-md-12">
                                    <div>
                                        <label style="font-size: 15px;">{{ review.rate!= undefined ?review.rate :"" }}</label>
                                        <label> / 5</label>
                                    </div>
                                    <span v-if="review.rate!=undefined">
                                        <i :style="review.rate >= 1 ? 'color: #fec42d;' : 'color: #bbb;'"  style="font-size:16px;" class="fa fa-star"></i>
                                    </span>&nbsp;
                                    <span v-if="review.rate!=undefined">
                                        <i :style="review.rate >= 2 ? 'color: #fec42d;' : 'color: #bbb;'"  style="font-size:16px;" class="fa fa-star"></i>
                                    </span>&nbsp;
                                    <span v-if="review.rate!=undefined">
                                        <i :style="review.rate >= 3 ? 'color: #fec42d;' : 'color: #bbb;'"  style="font-size:16px;" class="fa fa-star"></i>
                                    </span>&nbsp;
                                    <span v-if="review.rate!=undefined">
                                        <i :style="review.rate >= 4 ? 'color: #fec42d;' : 'color: #bbb;'"  style="font-size:16px;" class="fa fa-star"></i>
                                    </span>&nbsp;
                                    <span v-if="review.rate!=undefined">
                                        <i  :style="review.rate >= 5 ? 'color: #fec42d;' : 'color: #bbb;'"  style="font-size:16px;" class="fa fa-star"></i>
                                    </span>&nbsp;
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-if="rowcount !== 0" class="row text-right">
            <div class="col-sm-12">
                <div class="mt-3">
                    <b-pagination v-model="currentPage" :per-page="pageSize" :total-rows="rowcount"></b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import APIProduct from "@/mixins/merchant/APIProduct";
import mixinCommon from "@/mixins/APICommon";
export default {
  mixins: [mixinCommon,APIProduct],
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Notification Center",
          href: "/merchant-notification-center",
          active: true
        }
      ],
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      tableItems: [],
      // tableItems: [
      //   {
      //    _id:'123',
      //    question: "sample question ???",
      //    customerName: "new1",
      //    date: "2019-12-05",
      //    reply: "answer 01"
      //   }
      // ],
      merchNotificationList: [],
      merchInqAnsForm:{
        _id: "",
        answer: ""
      },
      singInq: "",
      starFive: '<i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>',
      starFour: '<i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>',
      starThree: '<i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>',
      starTwo: '<i class="fa fa-star"></i><i class="fa fa-star"></i>',
      starOne: '<i class="fa fa-star"></i>',
    }
  },
  watch: {
    searchKey: function(val) {
      // this.searchCustomers(val);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
        // this.setNotificationList();
      }
    },
    pageSize: function() {
      // this.searchCustomers(this.searchKey);
    }
  },
  created(){
    this.handleBreadcrumb();
    this.handleGetStores();
    this.setCount();
    this.setNotificationList();
  },
  computed: {
   
  },
  methods: {
    formatDate(date){
        var myDate = new Date(date);
        var myDate_string = myDate.toISOString();
        var myDate_string = myDate_string.replace("T"," ");
        var myDate_string = myDate_string.substring(0, myDate_string.length - 5);
        return myDate_string
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    handleGetStores: async function(page) {
      try {
          // this.searchCustomers("");
      } catch (error) {
          throw error;
      }
    },
    searchCustomers: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      if (!keyword || keyword.length === 0) {
        this.tableItems = this.merchNotificationList.slice(0, pagesize);
        // this.rowcount = this.merchNotificationList.length;
      } else {
        this.tableItems = this.merchNotificationList
          .filter(obj => {
            {
              return (
                (obj._id &&
                  obj._id.toLowerCase().includes(keyword.toLowerCase())) 
              );
            }
          })
          .slice(0, pagesize);

          // this.rowcount = this.merchNotificationList.length;
      }
    },   
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      let endsize = pagesize * currentPage;
      let startsize = 0;
      if(currentPage === 1){
        startsize = 0;
      } else {
        startsize = endsize - (pagesize) ;
      }     
      this.tableItems = this.merchNotificationList.slice(startsize, endsize);
    },
    handleView: function() {
      this.$router.push({ name: "Order Configure" });
    },
    setNotificationList: async function(){
     let store="";
     let page="";
     let perpage="";
      try {
			// alert(this.merchantId);
      let response = await this.getNotificationList(store,page,perpage); 
      this.merchNotificationList = response;

      console.log("===== test 12345678 =======");
      console.log(this.merchNotificationList);


      this.searchCustomers();

      // this.merchPrdQuestionList = response.map(obj=> {
      //   let dats = obj.question_date.split("GMT");
      //   obj.question_date = dats[0];
      //   return obj;
      // });

		  } catch (error) {
			this.$swal.fire({
      position: 'center',
      type: 'error',
      title: 'Error',
      showConfirmButton: false,
      timer: 1500
      }) 
		  }
    },
    setCount: async function(){
     let store="";
     let page="";
     let perpage="";
      try {
			// alert(this.merchantId);
      let response = await this.getNotificationList(store,page,perpage); 
      this.rowcount = response.length;
		  } catch (error) {
        throw (error);
      }
    },
    submitInquiryAnswers: async function(e){
      e.preventDefault();
      try {
        let response = await this.putInquiryAnswers(this.merchInqAnsForm); 
        // this.merchPrdQuestionList = response;
        this.setMerchQuestionList();
        this.$swal.fire({
        position: 'center',
        type: 'success',
        title: "Submitted...",
        showConfirmButton: false,
        timer: 1500
        })
		    const ndlclsbtn = this.$refs.mdlCloseBtn
        ndlclsbtn.click()
      } catch (error) {
        this.$swal.fire({
        position: 'center',
        type: 'error',
        title: error.data.message,
        showConfirmButton: false,
        timer: 1500
        }) 
      }
    },
    deleteInquiry: async function (id){
      // alert(id)

      if(confirm("Do you really want to delete?")){
          try {
          let response = await this.delInquiry(id); 
          this.$swal.fire({
          position: 'center',
          type: 'success',
          title: "Deleted..",
          showConfirmButton: false,
          timer: 1500
          })
        } catch (error) {
          this.$swal.fire({
          position: 'center',
          type: 'error',
          title: 'Error',
          showConfirmButton: false,
          timer: 1500
          }) 
        }
      }
    },
    getInqId: function(id,ques,ans){
      this.merchInqAnsForm._id = id;
      this.merchInqAnsForm.answer = ans;
      this.singInq = ques;
    } 
  }
}
</script>
<style >
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.action-style {
  width: 205px;
}
.report-admin-btn{
  margin-right: 5px;
}
.actions-btn .report-admin-btn:hover{
  color: #ffffff;
}
.actions-btn .report-admin-btn:focus{
  color: #ffffff;
}
.fa-star{
  color: yellow;
}
.user-attached-images img{
  display: block;
  width: 95%;
  margin: 0 auto 12px auto;
}
</style>