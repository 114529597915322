<template>
  <div> 
    <div class="panel-body row">
      <div class="col-sm-9" style="padding-left:0px;">
        <div class="input-group col-sm-3" style="padding-left:15px;">
          <input type="text" v-model="searchKey" class="form-control" id="input-store" placeholder="Search" value="" name="voucher">          
        </div>
      </div>
      <div class="col-sm-3" style="padding-right:0px;">
      </div>
    </div>
    <div id="table-content">
      <table id="example" class="table table-striped table-border-out" >
        <thead>
          <tr>
            <th scope="col" class="head-table"></th>
            <th scope="col" class="head-table" style="width: 200px;">Product</th>
            <th scope="col" class="head-table">Date</th>
            <th scope="col" class="head-table" style="width: 200px;">Customer Name</th>
            <th scope="col" class="head-table" style="width: 200px;">Question</th>
            <th scope="col" class="head-table" style="width: 200px;">Reply</th>
            <th scope="col" class="head-table" style="width: 210px;">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="column in merchPrdQuestionList" :key="column._id">
            <td  style="min-width: 100px;" class="text-left"><img class="img-thumbnail" width="80px" :src="column.product.images[0].image" /> </td>
            <td><a target="_blank" :href="'#/product?pid='+column.product._id"><b>{{column.product.product_name}}</b></a></td>
            <td style="min-width: 120px;"><b>{{ column.question_date}}</b></td>
            <td style="text-align: center;" v-if="column.user"><b>{{ column.user.first_name }} {{ column.user.last_name }}</b></td>
            <td style="text-align: center;" v-if="!column.user"><b>- -</b></td>
            <td style="text-align: center;"><b>{{ column.question }}</b></td>
            <td style="text-align: center;" v-if="column.answer"><b>{{ column.answer }}</b></td>
            <td style="text-align: center;" v-else-if="!column.answer"><b>--</b></td>
            <td class="action-style actions-btn" style="width: 160px;">
              <button @click="getInqId(column._id,column.question,column.answer?column.answer:'')" data-toggle="modal" data-target="#mdlReportThisProduct" class="btn btn-sm btn-success report-admin-btn" style="background-color: #2ba12f;">Answer</button>
              <button type="button" class="btn btn-sm btn-danger" @click="deleteInquiry(column._id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
      <b v-if="this.merchPrdQuestionList.length === 0"><p class="text-center">No records found</p></b>  
    </div>
    <div class="row text-right">
      <div class="col-sm-12">
         <div class="mt-3">
        <b-pagination 
        v-model="currentPage" 
        :per-page="pageSize"
        :total-rows="rowcount" 
        align="right"></b-pagination>
      </div>  
      </div>
    </div>
     <!-- Question Report Modal Start-->
	<div class="modal" id="mdlReportThisProduct" tabindex="-1" role="dialog">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
        <form @submit="submitInquiryAnswers">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="myModalLabel">Reply to Question</h4>
        </div>
        <div class="modal-body">
              <div class="form-group">
                <h5>Question: {{singInq}}</h5>
                <label for="comment">Answer:</label>
                <input type="hidden" required name="_id" v-model="merchInqAnsForm._id">
                <textarea class="form-control" rows="5" name="answer" v-model="merchInqAnsForm.answer" id="answer"></textarea>
              </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal" ref="mdlCloseBtn">Close</button>
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
        </form> 
			</div>
		</div>
	</div>
	<!-- Question Report Modal End -->
  </div>
 
</template>
<script>
import APIProduct from "@/mixins/merchant/APIProduct";
import mixinCommon from "@/mixins/APICommon";
import mixinStore from "@/mixins/merchant/APIStore";


export default {
  mixins: [mixinCommon,APIProduct,mixinStore],
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Notification Center",
          href: "/merchant-notification-center",
          active: true
        }
      ],
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      tableItems: [],
      storeListData: [],
      // tableItems: [
      //   {
      //    _id:'123',
      //    question: "sample question ???",
      //    customerName: "new1",
      //    date: "2019-12-05",
      //    reply: "answer 01"
      //   }
      // ],
      merchPrdQuestionList: [],
      merchInqAnsForm:{
        _id: "",
        answer: ""
      },
      singInq: ""
    }
  },
  watch: {
    searchKey: function(val) {
      this.$emit("searchQues",val);
      this.searchCustomers(val);
    },
    // currentPage: function(val) {
    //   if (val) {
    //     this.pagination(this.pageSize, val);
    //   }
    // },
    // pageSize: function() {
    //   this.searchCustomers(this.searchKey);
    // }
  },
  created(){
    this.handleBreadcrumb();
    this.handleGetStores();
    this.setMerchQuestionList();
  },
  computed: {
   
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    handleGetStores: async function() {
      try {
        let response = await this.getStores();
        this.storeListData = response;
      } catch (error) {
        console.log(error);
      }
    },
    searchCustomers: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      let merchPrdQuestionList = this.merchPrdQuestionList;
      if (!keyword || keyword.length === 0) {
        this.merchPrdQuestionList = this.tableItems;
        this.rowcount = this.merchPrdQuestionList.length;
      } else {
         this.merchPrdQuestionList = merchPrdQuestionList
          .filter(obj => {
            {
              return (
                (obj.store_name &&
                  obj.store_name.toLowerCase().includes(keyword.toLowerCase())) ||
                (obj.product.product_name &&
                  obj.product.product_name.toLowerCase().includes(keyword.toLowerCase())) || 
                (obj.question_date &&
                  obj.question_date.toLowerCase().includes(keyword.toLowerCase())) ||
                (obj.question &&
                  obj.question.toLowerCase().includes(keyword.toLowerCase())) || 
                (obj.answer &&
                  obj.answer.toLowerCase().includes(keyword.toLowerCase())) ||
                (obj.fullName &&
                  obj.fullName.toLowerCase().includes(keyword.toLowerCase()))
              );
            }
          })
          .slice(0, pagesize);

          this.rowcount = this.merchPrdQuestionList.length;
      }
      
    },   
    // pagination: function(pagesize, currentPage) {
      
    //   let pagesizeint = parseInt(this.pageSize);

    //   let endsize = pagesize * currentPage;
    //   let startsize = 0;

    //   if(currentPage === 1){
    //     startsize = 0;
    //   } else {
    //     startsize = endsize - (pagesize) ;
    //   }     

    //   this.tableItems = this.storeListData.slice(startsize, endsize);

    // },
    handleView: function() {
      this.$router.push({ name: "Order Configure" });
    },
    dateToDMY(date) {
        let d = date.getDate();
        let m = date.getMonth() ; //Month from 0 to 11
        var y = date.getFullYear();
        return '' + (d <= 9 ? '0' + d : d) + '-' + (m<=9 ? '0' + m : m) + '-' + y;
    },
    setMerchQuestionList: async function(){
      try {
			// alert(this.merchantId);
			let response = await this.getMerchQuestionList(); 
      this.tableItems = response.map(obj=> {
      //   let dats = obj.question_date.split(" ");
      //   let month = "01"
      //   if (dats[1] === "Jan") {
      //     month = "01";
      //   } else if (dats[1] === "Feb"){
      //     month = "02";
      //   } else if (dats[1] === "Mar"){
      //     month = "03";
      //   } else if (dats[1] === "Apr"){
      //     month = "04";
      //   } else if (dats[1] === "May"){
      //     month = "05";
      //   } else if (dats[1] === "Jun"){
      //     month = "06";
      //   } else if (dats[1] === "Jul"){
      //     month = "07";
      //   } else if (dats[1] === "Aug"){
      //     month = "08";
      //   } else if (dats[1] === "Sep"){
      //     month = "09";
      //   } else if (dats[1] === "Oct"){
      //     month = "10";
      //   } else if (dats[1] === "Nov"){
      //     month = "11";
      //   } else if (dats[1] === "Dec"){
      //     month = "12";
      //   }
      //   let mon = [];
      //   obj.question_date = dats[3] + " - " + month + " - " + dats[2];
      //   return obj;
        if (obj.question_date != undefined) {
          let date = this.dateToDMY(new Date(obj.question_date));
          obj.question_date = date;
        }
        if (obj.user!= null) {
          let fullName = obj.user.first_name + " " + obj.user.last_name;
          obj.fullName = fullName;
        } else {
          obj.fullName = "";
        }

        this.storeListData.map(item=> {
          if (item._id === obj.store) {
            obj.store_name = item.store_name
          }
        });
        return obj;
      });

      this.merchPrdQuestionList = this.tableItems;

		  } catch (error) {
			this.$swal.fire({
      position: 'center',
      type: 'error',
      title: 'Error',
      showConfirmButton: false,
      timer: 1500
      }) 
		  }
    },
    submitInquiryAnswers: async function(e){
      e.preventDefault();
      try {
        let response = await this.putInquiryAnswers(this.merchInqAnsForm); 
        // this.merchPrdQuestionList = response;
        this.setMerchQuestionList();
        this.$swal.fire({
        position: 'center',
        type: 'success',
        title: "Submitted...",
        showConfirmButton: false,
        timer: 1500
        })
		    const ndlclsbtn = this.$refs.mdlCloseBtn
        ndlclsbtn.click()
      } catch (error) {
        this.$swal.fire({
        position: 'center',
        type: 'error',
        title: error.data.message,
        showConfirmButton: false,
        timer: 1500
        }) 
      }
    },
    deleteInquiry: async function (id){
      // alert(id)

      if(confirm("Do you really want to delete?")){
          try {
          let response = await this.delInquiry(id); 
          this.setMerchQuestionList();
          this.$swal.fire({
          position: 'center',
          type: 'success',
          title: "Deleted..",
          showConfirmButton: false,
          timer: 1500
          })
        } catch (error) {
          this.$swal.fire({
          position: 'center',
          type: 'error',
          title: 'Error',
          showConfirmButton: false,
          timer: 1500
          }) 
        }
      }
    },
    getInqId: function(id,ques,ans){
      this.merchInqAnsForm._id = id;
      this.merchInqAnsForm.answer = ans;
      this.singInq = ques;
    } 
  }
}
</script>
<style >
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.action-style {
  width: 205px;
}
.report-admin-btn{
  margin-right: 5px;
}
.actions-btn .report-admin-btn:hover{
  color: #ffffff;
}
.actions-btn .report-admin-btn:focus{
  color: #ffffff;
}
</style>