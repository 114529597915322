<template>
  <div> 
    <div class="panel-body row">
      <div class="col-sm-9" style="padding-left:0px;">
        <div class="input-group col-sm-3" style="padding-left:15px;">
          <input type="text" v-model="searchKey" class="form-control" id="input-store" placeholder="Search Announcement" value="" name="voucher">          
        </div>
      </div>
      <div class="col-sm-3" style="padding-right:0px;">
      </div>
    </div>
    <div style="height: 43vh;" id="table-content">  
      <table id="example" class="table table-striped table-border-out" >
        <thead>
          <tr>
            <th scope="col" class="head-table">Announcement Title</th>
            <th scope="col" class="head-table">Date</th>
          </tr>
        </thead>
        <div class="col-md-12" id="preview-area" v-if="tableItems.length === 0">
            <div class="row text-center">
                <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                </div>
            </div>
        </div>
        <tbody>
          <tr v-for="(column, index) in tableItems" :key="index">
            <td ><b>{{ column.title }}</b></td>
            <td ><b>{{ column.formatted_date }}</b></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row text-right">
      <div class="col-sm-12">
         <div class="mt-3">
        <b-pagination 
        v-model="currentPage"
        :per-page="pageSize"
        :total-rows="rowcount" 
        align="right"></b-pagination>
      </div>  
      </div>
    </div>
  </div>
 
</template>
<script>
import APIProfile from '../../../mixins/merchant/APIProfile';
export default {
  name: 'Announcement',
  mixins: [APIProfile],
  data() {
    return {
      breadcrumb: [
        {
          text: "Notification Center",
          href: "/merchant-notification-center",
          active: true
        }
      ],
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      tableItems: [],
      announcementList: [],
      blockLoading: true
    }
  },
  sockets: {
  },
  watch: {
    searchKey: function(val) {
      this.searchAnnouncement(val);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
        this.getAnnouncementList(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchAnnouncement(this.searchKey);
    }
  },
  created() {
    this.handleBreadcrumb();
    this.getAnnouncementList(this.pageSize, this.currentPage);
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getAnnouncementList: async function(val, val1) {
      try {
        let array = await this.getAnnouncementAll();
        let res = await this.getAnnouncementInfo(val1, val);
        for (let x = 0; x < res.length; x++) {
          res[x].formatted_date = res[x].date.split("T")[0];
        }
        this.announcementList = res;
        this.rowcount = array.length;
        this.$emit("count", array.length);
        this.blockLoading = false;
        this.searchAnnouncement("");
		  } catch (error) {
        throw error;
      }
    },
    searchAnnouncement: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      if (!keyword || keyword.length === 0) {
        this.tableItems = this.announcementList.slice(0, pagesize);
        // this.rowcount = this.announcementList.length;
      } else {
        this.tableItems = this.announcementList
          .filter(obj => {
            {
              return (
                (obj.title &&
                  obj.title.toLowerCase().includes(keyword.toLowerCase()))
              );
            }
          })
          .slice(0, pagesize);
        // this.rowcount = this.announcementList.length;
      } 
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      let endsize = pagesize * currentPage;
      let startsize = 0;
      if(currentPage === 1){
        startsize = 0;
      } else {
        startsize = endsize - (pagesize) ;
      }
      this.tableItems = this.announcementList.slice(startsize, endsize);
    }
  }
}
</script>
<style >
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.action-style {
  width: 205px;
}
.report-admin-btn{
  margin-right: 5px;
}
.actions-btn .report-admin-btn:hover{
  color: #ffffff;
}
.actions-btn .report-admin-btn:focus{
  color: #ffffff;
}
</style>