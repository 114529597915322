import Domain from "./domain";
export default {
    methods: {
        getMainCategories: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/categories/all"
                );
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getCategoryDetailById: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/category/details/" + id
                );
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getCountry: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/country/details"
                );
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getProvince: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/country/province/details/" + id
                );
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getRegion: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/country/region/details/" + id
                );
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getCity: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/country/city/details/" + id
                );
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getReviewByPid: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/product/review/" + id
                );
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        postBuyerRestPassword: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/auth/forgot/password",
                    obj
                );
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        postMerchantRestPassword: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "merchant/auth/forgot/password",
                    obj
                );
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        postDeliverRestPassword: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "deliver/auth/forgot/password",
                    obj
                );
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        getAdvertisments: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/advertisement/details"
                );
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        getStoreRating: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/product/review/store/" + id
                );
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        getCartCashLimit: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/company/setting/policy/details"
                );
                if (!response || !response.data) {
                    throw Error("resource not found.");
                }
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        putContactUs: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "common/contact/us",
                    obj
                );
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        getTopBrands: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/top/cochchi/brand"
                );
                return response.data;
            } catch (error) {
                throw error.response;
            }
        },
        getMostPopulerCatList: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/top/cochchi/category"
                );
                return response.data;
            } catch (error) {
                throw error.response;
            }
        }
    }
};