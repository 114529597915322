import Domain from "../domain";
export default {
  methods: {
    getProductNotificationsApi: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/product/stock/notification",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
     
  }
};
