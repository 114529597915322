<!-- <template>
    <div>
        <ul class="nav nav-tabs">
            <li @click="updateURLType(1)" :class="[activetab === 1 ? 'in active': '']"><a data-toggle="tab" href="#customerquestions">Customer Questions</a></li>
            <li @click="updateURLType(2)" :class="[activetab === 2 ? 'in active': '']"><a data-toggle="tab" href="#order">Order <span class="badge badge-pill badge-success badge-count">{{ orderCount }}</span></a></li>
            <li @click="updateURLType(3)" :class="[activetab === 3 ? 'in active': '']"><a data-toggle="tab" href="#announcement">Announcement <span class="badge badge-pill badge-success badge-count">{{ count }}</span></a></li>
            <li @click="updateURLType(5)" :class="[activetab === 4 ? 'in active': '']"><a data-toggle="tab" href="#product">Product <span class="badge badge-pill badge-success badge-count">{{ productNotificationCount }}</span></a></li>
            <li @click="updateURLType(6)" :class="[activetab === 5 ? 'in active': '']"><a data-toggle="tab" href="#ratingandreviews">Rating And Reviews</a></li>
        </ul>
        <div class="tab-content">
            <div id="customerquestions" class="tab-pane fade" :class="[activetab === 1 ? 'in active': '']">
                <CustomerQuestions />
            </div>
            <div id="order" class="tab-pane fade" :class="[activetab === 2 ? 'in active': '']">
                <NotificationsOrders @orderCount="setNotificationOrder"/>
            </div>
            <div id="announcement" class="tab-pane fade" :class="[activetab === 3 ? 'in active': '']">
                <NotificationAnnouncement @count="announcementCount"/>
            </div>
            <div id="product" class="tab-pane fade" :class="[activetab === 4 ? ' in active': '']">
                <NotificationsProduct  @productNotificationCount="setNotificationProductCount"/>
            </div>
            <div id="ratingandreviews" class="tab-pane fade" :class="[activetab === 5 ? ' in active': '']">
                <RatingsAndReviews />
            </div>
        </div>         
    </div>
</template> -->
<template>
    <div>
        <ul class="nav nav-tabs">
            <li @click="updateURLType(1)" class="active">
                <a data-toggle="tab" href="#customerquestions">Customer Questions</a>
            </li>
            <li @click="updateURLType(2)">
                <a data-toggle="tab" href="#order">Order <span class="badge badge-pill badge-success badge-count"> <!-- {{ orderCount }} --></span></a>
            </li>
            <li @click="updateURLType(3)">
                <a data-toggle="tab" href="#announcement">Announcement <span class="badge badge-pill badge-success badge-count"> <!-- {{ count }} --></span></a>
            </li>
            <li @click="updateURLType(5)">
                <a data-toggle="tab" href="#product">Product <span class="badge badge-pill badge-success badge-count"> <!--{{ productNotificationCount }} --></span></a>
            </li>
            <li @click="updateURLType(6)">
                <a data-toggle="tab" href="#ratingandreviews">Rating And Reviews</a>
            </li>
        </ul>
        <div class="tab-content content-inner content-inner-profile">
            <div id="customerquestions" class="tab-pane fade in active classWell">
                <CustomerQuestions ref="questionRef" />
            </div>
            <div id="order" class="tab-pane fade classWell">
                <NotificationsOrders ref="ordersRef" @orderCount="setNotificationOrder"/>
            </div>
            <div id="announcement" class="tab-pane fade classWell">
                <NotificationAnnouncement ref="announcementRef" @count="announcementCount"/>
            </div>
            <div id="product" class="tab-pane fade classWell">
                <NotificationsProduct ref="productRef"  @productNotificationCount="setNotificationProductCount"/>
            </div>
            <div id="ratingandreviews" class="tab-pane fade classWell">
                <RatingsAndReviews />
            </div>
        </div>         
    </div>
</template>
<script>
import CustomerQuestions from '@/components/Merchant/notifications/CustomerQuestions'
import NotificationsOrders from '@/components/Merchant/notifications/NotificationsOrders.vue'
import NotificationAnnouncement from '@/components/Merchant/notifications/NotificationAnnouncement.vue'
import NotificationCampaing from '@/components/Merchant/notifications/NotificationCampaing.vue'
import NotificationsProduct from '@/components/Merchant/notifications/NotificationsProduct.vue'
import RatingsAndReviews from '@/components/Merchant/notifications/RatingsAndReviews'
export default {
    name: 'MerchantNotoficationCenter',
    data(){
    return{
        productNotificationCount: 0,
        orderCount: 0,
        selectedContactType: "",
        count: "",
        activetab: 1,
        // questionRef:"",
        // ordersRef:"",
        // announcementRef:"",
        // productRef:""
    }
    },
    components: { 
        CustomerQuestions,
        RatingsAndReviews, 
        NotificationsOrders,
        NotificationAnnouncement,
        NotificationCampaing,
        NotificationsProduct 
    },
    watch:{
        page () {
            this.fetchURLparams();
        }
    },
    computed: {
        page () {
            return this.$route.query.type || 1
        }
    },
    mounted:function(){
        this.fetchURLparams();
    },
    created: function () {},
    methods:{
        fetchURLparams(){
            if(this.$route.query.type){
                this.activetab = parseInt(this.$route.query.type);
            }
        },
        updateURLType(type){
            this.$refs.questionRef.$el.children[0].childNodes[0].childNodes[0].childNodes[0].value = "";
            this.$refs.questionRef.searchKey = "";
            this.$refs.questionRef.searchCustomers("");
            this.$refs.ordersRef.$el.children[0].childNodes[0].childNodes[0].childNodes[0].value = "";
            this.$refs.ordersRef.searchKey = "";
            this.$refs.ordersRef.searchOrder();
            this.$refs.announcementRef.$el.children[0].childNodes[0].childNodes[0].childNodes[0].value = "";
            this.$refs.announcementRef.searchKey = "";
            this.$refs.announcementRef.searchAnnouncement();
            this.$refs.productRef.$el.children[0].childNodes[0].childNodes[0].childNodes[0].value = "";
            this.$refs.productRef.searchKey = "";
            this.$refs.productRef.searchProduct();
            this.$router.push('/merchant-notification-center?type=' + type).catch(err => {});
            this.activetab = type;
        },
        setNotificationProductCount(payload){
            this.productNotificationCount = payload;
        },
        announcementCount(length) {
            this.count = length;
        },
        setNotificationOrder(payload){
            this.orderCount = payload;
        }
    }
}
</script>
<style>
.badge-count{
    margin-left: 5px;
}
</style>
