<template>
  <div> 
    <div class="panel-body row">
      <div class="col-sm-9" style="padding-left:0px;">
        <div class="input-group col-sm-3" style="padding-left:15px;">
          <input type="text" v-model="searchKey" class="form-control" id="input-store" placeholder="Search Product"  name="voucher">          
        </div>
      </div>
      <div class="col-sm-3" style="padding-right:0px;">
      </div>
    </div>
    <!-- <div style="height: 43vh;" id="table-content">   -->
    <div style="overflow-x: scroll;">  
      <table id="example1" class="table table-striped table-border-out" >
        <thead>
          <tr>
            <th scope="col" class="head-table" style="width: 60px;">Image</th>
            <!-- <th scope="col" class="head-table" style="width: 200px;">Product Id</th> -->
            <th scope="col" class="head-table" style="width: 400px; text-align: inherit;">Product Name</th>
            <th scope="col" class="head-table" style="width: 400px; text-align: inherit;">Store Name</th>
            <th scope="col" class="head-table" style="text-align: inherit;">Message</th>
            <th scope="col" class="head-table" style="text-align: inherit;">Date</th>
            <!-- <th scope="col" class="head-table" style="width: 200px;">Status</th> -->
          </tr>
        </thead>
        <div class="col-md-12" id="preview-area" v-if="!blockdata">
            <div class="row text-center">
                <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                </div>
            </div>
        </div>
        <tbody>
          <tr v-for="column in data" :key="column._id">
            <td style="min-width: 100px;"><b><img :src="column.image" class="img-product"></b></td>
            <td style="min-width: 300px;"><b>{{ column.product.product_name }}</b></td>
            <td style="min-width: 80px;"><b>{{ column.store_name }}</b></td>
            <td style="min-width: 400px;"><b>{{ column.notification }}</b></td>
            <td style="min-width: 120px;"><b>{{ column.date }}</b></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row text-right">
      <div class="col-sm-12">
        <div class="mt-3">
          <b-pagination 
            v-model="currentPage" 
            :per-page="pageSize"
            :total-rows="rowcount" 
            align="right"
          />
        </div>  
      </div>
    </div>
  </div>
 
</template>
<script>
import APINotification from "@/mixins/merchant/APINotification.js";
import mixinStore from "@/mixins/merchant/APIStore";

export default {
  mixins: [APINotification, mixinStore],
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Notification Center",
          href: "/merchant-notification-center",
          active: true
        }
      ],
      pageSize: 10,
      blockdata: true,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      storeListData: [],
      tableItems: [],
      data: []
    }
  },
  watch: {
    searchKey :function(val){
      this.searchProduct(val)
    },
    currentPage :function(val){
      this.pagination(this.pageSize, val);
    },
    pageSize :function(){
      this.searchProduct(this.searchKey)
    }
  },
  created(){
    this.handleGetStores();
    this.handleBreadcrumb();
    this.getProductNotifications();
  },
  computed: {},
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },handleView: function() {
      this.$router.push({ name: "Order Configure" });
    },
    searchProduct: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      // let data = this.data;
      if (!keyword || keyword.length === 0) {
        this.data = this.tableItems.slice(0, pagesize);
        this.rowcount = this.tableItems.length;
      } else {
        this.data = this.tableItems.filter(obj => {
            {
              return (
                (obj.store_name &&
                  obj.store_name
                    .toLowerCase()
                    .includes(keyword.toLowerCase())) ||
                (obj.product.product_name &&
                  obj.product.product_name.toLowerCase().includes(keyword.toLowerCase())) ||
                (obj.notification &&
                  obj.notification.toLowerCase().includes(keyword.toLowerCase())) ||
                (obj.date &&
                  obj.date.toLowerCase().includes(keyword.toLowerCase()))
              );
            }
          })
          .slice(0, pagesize);

          this.rowcount = this.data.length;
      } 
    },
    dateToDMY(date) {
        let d = date.getDate();
        let m = date.getMonth() + 1; //Month from 0 to 11
        var y = date.getFullYear();
        return '' + (d <= 9 ? '0' + d : d) + '-' + (m<=9 ? '0' + m : m) + '-' + y;
    },
    handleGetStores: async function() {
      try {
        let response = await this.getStores();
        this.storeListData = response;
      } catch (error) {
        console.log(error);
      }
    },
    getProductNotifications: async function(){
      try {
        this.blockdata = false;
        let response = await this.getProductNotificationsApi();
        if(response) {
          // this.tableItems = response;
          this.tableItems = response.map(obj=> {
            if (obj.product.updated_date != undefined) {
              var now = this.dateToDMY(new Date(obj.product.updated_date));
              obj.updated_date = now;
            }

            // images[0].image
            if (!obj.images[0].image && obj.images[0].image == undefined) {
              obj.image = 'https://restaurant.gandhinagaronline.in/images/profile/default-product.jpg';
            } else {
              obj.image = obj.images[0].image;
            }
            obj.store_name = "";
            this.storeListData.map(item=> {
              if (item._id === obj.store) {
                obj.store_name = item.store_name
              }
            });
            return obj;
          }); 
          this.data = this.tableItems;
          this.blockdata = true;
          this.setProductNotificationCount();
          this.searchProduct("");
        }
		  } catch (error) {
        this.blockdata = true;
        throw error;
      }
    },
    pagination: function(pagesize, currentPage) {
      let endSize = pagesize * currentPage;
      let startsize = 0;

      if (currentPage === 1) {
        startsize = 0;
      } else {
        startsize = endSize - (pagesize);
      }

      this.data = this.tableItems.slice(startsize, endSize);
    },
    setProductNotificationCount: function(){
      this.$emit("productNotificationCount",this.tableItems.length);
    }
  }
}
</script>
<style >
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-product {
  width: 60px;
  height: 60px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.action-style {
  width: 205px;
}
.report-admin-btn{
  margin-right: 5px;
}
.actions-btn .report-admin-btn:hover{
  color: #ffffff;
}
.actions-btn .report-admin-btn:focus{
  color: #ffffff;
}
</style>